import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { exportPackages, fetchSafaKeys, listPackages } from "services/package";
import AddEditPackageModal from "./AddEditPackageModal";
import AddPaymentModal from "./AddEditPaymentModal";
import { Plus } from "./icons";
import PackageTable from "./PackageTable";

export const mapCurrencyToSelect = (currencies) => {
	return currencies.map((currency) => ({
		value: currency.id,
		label: currency.currency_code,
		...currency,
	}));
};

const mapCountryToSelect = (countries, lang) => {
	return countries.map((country) => ({
		value: country.country_code,
		label: country.names[lang],
		...country,
	}));
};

export default function Package() {
	// *** STATES ***
	const { allCurrencies, allCountries, locale } = useGlobalState();
	const [packages, setPackages] = useState([]);
	const [meta, setMeta] = useState(null);
	const [safaKeys, setSafaKeys] = useState([]);
	const [filters, setFilters] = useState({
		company_name: "",
		safa_key: "",
		country: "",
		page: 1,
	});

	const [addEditModal, setAddEditModal] = useState({
		open: false,
		type: "add", // add | edit
		data: {
			safa_key: null,
			package: "",
			contract: "",
			package_price: "",
			currency: null,
			start_date: null,
			end_date: null,
			package_type: null,
		},
	});
	const [addEditPaymentModal, setAddPaymentModal] = useState({
		open: false,
		type: "add", // add | edit
		data: {
			safa_visa_package_id: "",
			payment_method: null,
			amount: "",
			currency: null,
			payment_document: {
				fileDisplay: null,
				fileObject: null,
			},
			amount_pay: "",
			package_price: "",
			note: "",
			company_name: "",
		},
	});

	// *** FUNCTIONS ***
	const fetchPackages = async (page = filters?.page) => {
		const res = await listPackages({
			...filters,
			country: filters?.country?.id,
			page,
		});
		setPackages(res?.data.data);
		setMeta(res?.data.meta);
	};

	const exportPKGs = async () => {
		const res = await exportPackages({ filters });
		const blob = new Blob([res.data], {
			type: res.headers["content-type"],
		});

		const objectUrl = window.URL.createObjectURL(blob);

		const anchor = document.createElement("a");
		anchor.target = "_blank";
		anchor.href = objectUrl;
		anchor.setAttribute(
			"download",
			`packages-${moment().format("DD-MM-YYYY")}.xlsx`
		);
		anchor.click();
	};

	const handleOnChangeFilter = (key, value) => {
		setFilters({ ...filters, [key]: value });
	};

	const resetFilter = () => {
		setFilters({
			company: "",
			safa_key: "",
			country: "",
		});

		fetchPackages({});
	};

	const goTo = (page) => {
		setFilters({ ...filters, page });
		fetchPackages(page);
	};

	const toggleAddEditModal = (type) => {
		setAddEditModal({
			...addEditModal,
			open: !addEditModal.open,
			type,
			data: {
				safa_key: null,
				package: "",
				contract: "",
				package_price: "",
				currency: null,
				start_date: null,
				end_date: null,
				package_type: null,
			},
		});
	};

	const getSafaKeys = async (inputValue) => {
		if (inputValue.length > 2) {
			const res = await fetchSafaKeys(inputValue);
			const formated = res.data.data.map((item) => ({
				...item,
				value: item.id,
				label: item.safa_key,
				name: item.safa_key,
			}));

			setSafaKeys(formated);
		}
	};

	// *** USE EFFECTS ***
	useEffect(() => {
		fetchPackages();
	}, []);

	return (
		<div className="package">
			<h1 className="package__title">Pay package - Safa visa</h1>

			<div className="d-flex justify-content-end mt-4" style={{ gap: 8 }}>
				<ShowForPermission permission={["SafaVisa-Packages-List"]}>
					<button className="btn btn-secondary" onClick={exportPKGs}>
						Export File
					</button>
				</ShowForPermission>
				<ShowForPermission
					permission={["SafaVisa-Packages-Add", "SafaVisa-Packages-List"]}
				>
					<button
						className="btn btn-primary"
						onClick={() => toggleAddEditModal("add")}
					>
						<span>
							<Plus />
						</span>
						<span>Add Package</span>
					</button>
				</ShowForPermission>
			</div>

			<ShowForPermission permission={["SafaVisa-Packages-List"]}>
				<div className="mt-4">
					<div className="main-filter  our-border bg-white-blue py-2 mt-2 w-100">
						<div className="row align-items-end w-100">
							<div className="col-6 col-md-3 p-0">
								<TextField
									label="Company Name"
									placeholder="Company Name"
									name="company"
									id="company"
									value={filters.company}
									onChange={(e) =>
										handleOnChangeFilter("company_name", e.target.value)
									}
								/>
							</div>
							<div className="col-6 col-md-3 p-0">
								<TextField
									label="Safa Key"
									placeholder="Safa Key"
									name="safa_key"
									id="safa_key"
									value={filters.safa_key}
									onChange={(e) =>
										handleOnChangeFilter("safa_key", e.target.value)
									}
								/>
							</div>
							<div className="col-6 col-md-3 p-0">
								<SelectField
									label="Country"
									placeholder="Country"
									name="country"
									id="country"
									options={mapCountryToSelect(allCountries, locale)}
									value={filters.country}
									onChange={(country) =>
										handleOnChangeFilter("country", country)
									}
								/>
							</div>

							<div
								className="col-6 col-md-3 mb-3 d-flex"
								style={{ gap: "8px" }}
							>
								<button
									className="btn btn-primary"
									onClick={() => fetchPackages()}
								>
									Search
								</button>
								<button className="btn btn-secondary" onClick={resetFilter}>
									Reset
								</button>
							</div>
						</div>
					</div>
				</div>
			</ShowForPermission>

			<ShowForPermission permission={["SafaVisa-Packages-List"]}>
				<PackageTable
					packages={packages}
					fetchPackages={fetchPackages}
					safaKeys={safaKeys}
					allCurrencies={allCurrencies}
					setAddEditModal={setAddEditModal}
					setAddPaymentModal={setAddPaymentModal}
					addEditModal={addEditModal}
					addEditPaymentModal={addEditPaymentModal}
					filters={filters}
				/>
			</ShowForPermission>

			<ShowForPermission permission={["SafaVisa-Packages-List"]}>
				<div className="mt-3">
					{packages.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
				</div>
			</ShowForPermission>

			<AddEditPackageModal
				isOpen={addEditModal.open}
				toggle={() =>
					setAddEditModal({
						...addEditModal,
						open: false,
						data: {
							...addEditModal.data,
							safa_key: null,
							package: "",
							contract: "",
							package_price: "",
							currency: null,
							start_date: null,
							end_date: null,
						},
					})
				}
				addEditModal={addEditModal}
				setAddEditModal={setAddEditModal}
				fetchPackages={() => fetchPackages(filters)}
				safaKeys={safaKeys}
				setSafaKeys={setSafaKeys}
				getSafaKeys={getSafaKeys}
				allCurrencies={allCurrencies}
			/>

			<AddPaymentModal
				isOpen={addEditPaymentModal.open}
				toggle={() =>
					setAddPaymentModal({
						...addEditPaymentModal,
						open: false,
						data: {
							...addEditPaymentModal.data,
							payment_method: null,
							amount: "",
							currency: null,
							payment_document: {
								fileDisplay: null,
								fileObject: null,
							},
							amount_pay: "",
							package_price: "",
							note: "",
							company_name: "",
						},
					})
				}
				paymentModal={addEditPaymentModal}
				setAddPaymentModal={setAddPaymentModal}
				fetchPackages={() => fetchPackages()}
			/>
		</div>
	);
}

import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { fetchCycleTypes } from "services/lookups";
import TransportationRow from "./TransportationRow";

export default function AddTransportation({
	country,
	destination,
	setDestination,
	indexConfiguration,
	setIndexConfiguration,

	inputsState,
	setInputsState,

	arrayCountry,
	setArrayCountry,
	viewConfiguration,
	setviewConfiguration,
	countries,
	vehicleModel,
	vehicleType,
}) {
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCurrencies } = useGlobalState();

	//state no main
	//state main
	const [VisaRowState, setVisaRowState] = useState([]);
	const [alertToggle, setAlertToggle] = useState(false);

	// const handleVisa = (e, data) => {
	// 	console.log(viewConfiguration);
	// 	let newData = viewConfiguration;
	// 	let counrtyX = newData.filter((res) => res.country == data.country)[0];
	// 	counrtyX.visa_types.push({
	// 		id: e.value,
	// 		name: e.label,
	// 		residences: [{ residence: "", currency: "" }],
	// 	});
	// 	setviewConfiguration([...newData]);
	// };
	const handleDelete = (index, ind = 0) => {
		let clone = [...viewConfiguration];
		clone = clone.filter((res, index) => ind != index);
		setviewConfiguration(clone);
	};
	//Collapse
	const [isOpen, setIsOpen] = useState(true);
	const toggleButton = () => setIsOpen(!isOpen);

	//Fetch Countries
	const [CycleTypes, setCycleTypes] = useState([]);
	const [currencies, setCurrencies] = useState([]);

	useEffect(() => {
		async function fetchLookups() {
			const rese = await fetchCycleTypes();
			if (rese.status == 200 || rese?.data?.length > 0) {
				const format = rese?.data?.map((t) => ({
					value: t.id,
					label: t.name[locale],
					lan: t.name,
				}));
				setCycleTypes(format);
			}
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);

	return (
		<>
			{viewConfiguration && viewConfiguration?.length > 0 ? (
				<>
					{viewConfiguration?.map((configData, index) => (
						<div className="p-0 border mb-2">
							<div className=" header-add-visa mt-2 px-4 d-flex justify-content-between">
								<p className="country-visa">
									{configData?.country
										? configData?.country
										: configData?.destination?.name}
								</p>

								<ShowForPermission permission="Transportation-Configuration-Manage">
									<div className="visa-select d-flex align-items-center">
										<span
											className="btn-link text-danger mx-1 w-100"
											onClick={() => handleDelete(indexConfiguration, index)}
										>
											<i class="fas fa-trash"></i> Remove
										</span>
									</div>
								</ShowForPermission>
							</div>
							<Collapse isOpen={true}>
								<TransportationRow
									index={index}
									country={
										configData?.country
											? configData?.country
											: configData?.destination?.name
									}
									//visa={item}
									handleDelete={handleDelete}
									destination={destination}
									setDestination={setDestination}
									indexConfiguration={index}
									setIndexConfiguration={setIndexConfiguration}
									setviewConfiguration={setviewConfiguration}
									viewConfiguration={viewConfiguration}
									inputsState={(inputsState = configData?.residences)}
									setInputsState={setInputsState}
									arrayCountry={arrayCountry}
									setArrayCountry={setArrayCountry}
									configData={configData?.residences}
									allConfigData={configData}
									setVisaRowState={setVisaRowState}
									VisaRowState={VisaRowState}
									countries={countries}
									CycleTypes={CycleTypes}
									currencies={currencies}
									vehicleModel={vehicleModel}
									vehicleType={vehicleType}
									alertToggle={alertToggle}
									setAlertToggle={setAlertToggle}
								/>
							</Collapse>
						</div>
					))}
				</>
			) : (
				""
			)}
		</>
	);
}
